import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import { StickyContainer, Sticky } from 'react-sticky';

import logo from "../images/pravi-logo.svg";
import SortDownIcon from "../images/sort-down-solid.svg";
import Slider from "react-slick";
import ContactForm from "../components/Global/ContactForm"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AnchorLink from 'react-anchor-link-smooth-scroll'
import Modal from 'react-modal';

import Layout from '../components/layout'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "50%",
        minWidth: "370px"
    },
    overlay: {
        backgroundColor: "#303030bf",
        zIndex: "99"
    }
};

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};


function HomeTemplate(props) {
    // console.log(props)
    let data = props.data.wpgraphql;

    const [langSwitcherStatus, setLangSwitcherStatus] = useState(false)
    const [modalIsOpen, setIsOpen] = useState(false);

    const acf = data.page.homePageAcf;

    useEffect(() => {
        setIsOpen(true)
    }, [])

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://pravihotel.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://pravihotel.com${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }

        console.log(payload)

        return payload
    }

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }


    return (
        // <PageTransition>
        <Layout
            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={props.pageContext.availablePages}
            metaData={constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)}
            footerMenu={props.pageContext.footerMenu}
            template={data.template.contactInformationTemplate}
        >
            <StickyContainer>
                <Sticky topOffset={500}>
                    {({
                        style,
                        // the following are also available but unused in this example
                        isSticky,
                        wasSticky,
                        distanceFromTop,
                        distanceFromBottom,
                        calculatedHeight
                    }) => (
                            <header style={style} className="tmp-header">
                                <div id="nav-menu" className="navbar-collapse rq-menu-wrapper collapse" role="navigation">
                                    <div className="md:pl-16">
                                        <img src={logo} alt="pravi-logo" className="m-auto" style={{ width: "83px" }} />
                                    </div>
                                    <ul className="nav navbar-nav rq-menus ml-auto">
                                        <li className="active">
                                            <AnchorLink href='#about-us'>
                                                {props.pageContext.language === "EN" ? "About Us" : "Εταιρεία"}
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink href='#rooms'>
                                                {props.pageContext.language === "EN" ? "Rooms" : "Δωμάτια "}
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink href='#contact'>
                                                {props.pageContext.language === "EN" ? "Contact" : "Επικοινωνία  "}
                                            </AnchorLink>
                                        </li>
                                        <li className="lang-li">
                                            <div className="lang-switch-container flex">
                                                <span onClick={() => setLangSwitcherStatus(!langSwitcherStatus)}>{props.pageContext.language}</span>
                                                <span onClick={() => setLangSwitcherStatus(!langSwitcherStatus)}><img src={SortDownIcon} style={langSwitcherStatus ? { transform: "rotate(180deg)", marginTop: "0.5rem", width: "10px", marginLeft: "0.5rem" } : { width: "10px", marginLeft: "0.5rem" }} /></span>
                                                <ul className={`lang-ul ${langSwitcherStatus ? "block" : "hidden"}`}>
                                                    {props.pageContext.availablePages.map((lang, i) => (
                                                        <li>
                                                            <Link to={lang.uri.includes("home") ? "/el/" : "/" + lang.uri} style={{ color: "#454545" }}>
                                                                {lang.language.name}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </header>
                        )}
                </Sticky>
                <div>
                    <BackgroundImage
                        Tag="section"
                        className="rq-banner-area"
                        fluid={acf.backgroundImage.imageFile.childImageSharp.fluid}
                        backgroundColor={`#040e18`}
                    >
                        <div className="rq-banner-area-mask">
                            <div className="container">
                                <div className="bq-banner-text">
                                    <div className="bq-banner-text-middle">
                                        <img src={logo} alt="pravi-logo" className="m-auto" />
                                        <h1>{acf.titleH1}</h1>
                                        <h2>{acf.subtitleH2}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BackgroundImage>
                    <section id="about-us" className="rq-hotel-palace">
                        <div className="container ">
                            <div className="rq-hotel-text text-center">
                                <h2>{acf.sectitle}</h2>
                                <p>{acf.paragraph}</p>

                                <h2 className="text-center">{acf.offertitle}</h2>
                            </div>

                            <div className="row " style={{display:"flex",justifyContent: "center"}}>
                                {acf.offers.map((offer, i) => (
                                    <div key={`offer-${i}`} className="col-md-4 col-sm-4 col-xs-12 rq-our-offer">
                                        <div className="thumbnail">
                                            <div className="rq-img-wrapper">
                                                <Img fixed={offer.offerImage.imageFile.childImageSharp.fixed} alt={offer.offerImage.altText} />
                                            </div>

                                            <div className="caption">
                                                <h3><a href="#">{offer.title}</a></h3>
                                                <p>{offer.subtitle}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </section>
                    <section id="rooms" className="rq-suprime-area">
                        <div className="container">
                            <h1 className="text-center">{acf.roomtitle}</h1>
                            <div className="rq-suprime-logo text-center">
                                <img src={logo} alt="pravi-logo" className="m-auto sm-pravi" />
                            </div>


                            {acf.rooms.map((room, i) => {
                                if (i % 2 === 0) {
                                    return (
                                        <div className="rq-sup">
                                            <div className="row ">
                                                <div className="col-md-7 col-sm-7">
                                                    <div className="rq-sup-img-wrap">
                                                        <Img fluid={room.roomImage.imageFile.childImageSharp.fluid} alt={room.roomImage.altText} />
                                                    </div>
                                                </div>
                                                <div className="col-md-5 col-sm-5">
                                                    <div className="rq-suprime-text" dangerouslySetInnerHTML={{ __html: room.roomContent }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <React.Fragment>
                                            <div className="rq-sup hidden md:block">
                                                <div className="row">
                                                    <div className="col-md-5 col-sm-5">
                                                        <div className="rq-suprime-text" dangerouslySetInnerHTML={{ __html: room.roomContent }}></div>
                                                    </div>
                                                    <div className="col-md-7 col-sm-7">
                                                        <div className="rq-sup-img-wrap">
                                                            <Img fluid={room.roomImage.imageFile.childImageSharp.fluid} alt={room.roomImage.altText} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rq-sup block md:hidden">
                                                <div className="row ">
                                                    <div className="col-md-7 col-sm-7">
                                                        <div className="rq-sup-img-wrap">
                                                            <Img fluid={room.roomImage.imageFile.childImageSharp.fluid} alt={room.roomImage.altText} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5 col-sm-5">
                                                        <div className="rq-suprime-text" dangerouslySetInnerHTML={{ __html: room.roomContent }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                }
                            })}
                        </div>
                    </section>

                    <section className="rq-testimonial-owl-carousel overflow-x-hidden" style={{ position: "relative", top: "5px" }}>
                        <Slider {...settings}>
                            {acf.carouselRepeater.map((slide, i) => (
                                <div key={`cases-slide-${i}`} className="port-img">
                                    <div className="relative case-effect">
                                        <Img fluid={slide.slideImage.imageFile.childImageSharp.fluid} alt={slide.slideImage.altText} />
                                        <div className="mask1"></div>
                                        <div className="rq-port-center">
                                            <h4>{slide.slideTitle}</h4>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </section>

                    <section id="contact">
                        <ContactForm
                            form={data.template.contactInformationTemplate.form}
                            responses={data.template.contactInformationTemplate.responseMessages}
                            endPoint={"contactEmail"}
                            language={props.pageContext.language}
                            subjectRequired={true}
                            messageRequired={true}
                        />
                    </section>

                    {/* <Modal
                        isOpen={modalIsOpen}
                        style={customStyles}
                        contentLabel="Covid 19"
                    >
                        <div>
                            <button onClick={closeModal} style={{float:"right", fontSize: "2rem"}}>&#10005;</button>
                            <div dangerouslySetInnerHTML={{ __html: acf.popUp }} />
                        </div>
                    </Modal> */}

                </div>
            </StickyContainer>
        </Layout>
        // </PageTransition>
    )
}

export default HomeTemplate

export const pageQuery = graphql`
    query GET_PAGE($id: ID!, $themeTemplateUri: ID!) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                homePageAcf {
                    titleH1
                    subtitleH2
                    backgroundImage {
                        altText
                        sourceUrl
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 2080, quality:60){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    sectitle
                    paragraph
                    offertitle
                    offers{
                        title
                        subtitle
                        offerImage{
                            sourceUrl
                            altText
                            imageFile{
                                childImageSharp{
                                    fixed(width: 400, quality:100){
                                        base64
                                        width
                                        height
                                        src
                                        srcSet
                                    }
                                }
                            }
                        }
                    }
                    roomtitle
                    rooms{
                        roomContent
                        roomImage{
                            sourceUrl
                            altText
                            imageFile{
                                childImageSharp{
                                    fluid(maxWidth: 625, quality:100){
                                        base64
                                        aspectRatio
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                        sizes
                                    }
                                }
                            }
                        }
                    }
                    carouselRepeater{
                        slideTitle
                        slideImage{
                            sourceUrl
                            altText
                            imageFile{
                                childImageSharp{
                                    fluid(maxWidth: 500, quality:100){
                                        base64
                                        aspectRatio
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                        sizes
                                    }
                                }
                            }
                        }
                    }
                    popUp
                }
            }
            template(id: $themeTemplateUri, idType: SLUG) {
                contactInformationTemplate {
                    form {
                        title
                        subTitle
                        fullName
                        email{
                            label
                            errorMessage
                        }
                        subject
                        message{
                            label
                            errorMessage
                        }
                        telephone{
                            label
                            errorMessage
                        }
                        sendButton
                    }
                    responseMessages{
                        success
                        failed
                    }
                    informationTitle
                    telephoneNumber
                    supportEmail
                    address {
                        addressGoogleLink
                        addressLabel
                    }
                }
            }
        }
    }
`
