import React, { useState, useRef, useEffect } from 'react'
import Img from "gatsby-image"
import Formsy from 'formsy-react';
import BackgroundImage from 'gatsby-background-image'
import axios from "axios"

import FormsyInput from "./../Formsy/formsyInput"
import FormsyTextArea from "./../Formsy/FormsyTextArea"

import "./ContactForm.css";


function ContactForm(props) {
    const data = props;
    const formRef = useRef(null);


    const [canSubmit, setCanSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [emailFailed, setEmailFailed] = useState(false);
    const [emailResponse, setEmailResponse] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    useEffect(() => {
        if(canSubmit){
            setShowErrorMessage(false)
        }
    },[canSubmit])


    function disableButton() {
        setCanSubmit(false);
    }

    function enableButton() {
        setCanSubmit(true);
    }

    function submit(model) {
        setLoading(true)

        if (model.fullName) {
            let payload = {
                ...model,
                language: props.language
            }

            axios.post(`https://admin.pravihotel.com//wp-json/myplugin/v1/${props.endPoint}`, payload)
            .then(function (response) {
                // handle success
                setEmailFailed(false)
                setLoading(false)
                setEmailSent(true)
                setEmailResponse(response.data)

            })
            .catch(function (error) {
                // handle error
                setLoading(false)
                setEmailFailed(true)
                setEmailResponse(data.responses.failed)
            })
        }
    }

    function test (){
        setShowErrorMessage(true)
    }

    return (
        <div className={`${ emailSent ? "email-sent-container" : ""} py-24`}>
            <div className={`m-auto ${ emailSent ? "max-w-none" : "max-w-4xl"} px-4 relative`} >
                {loading && (
                    <div className="loader-container">
                        <div className="loader">Loading...</div>
                    </div>
                )}

                { emailFailed && (
                    <p style={{color: "#b70000",fontSize: "1.8rem"}}>{emailResponse}</p>
                )}

                {!emailSent
                    ?
                    <Formsy 
                        onValidSubmit={submit} 
                        onValid={enableButton} 
                        onInvalid={disableButton} 
                        className={loading ? "opacity-25" : "opacity-100"}
                        ref={formRef}
                    >
                        <h2 className="text-6xl text-center" style={{color: "#c99616"}}>
                            {data.form.title}
                        </h2>
                        <div>
                            <p className="text-md text-center" style={{color: "#c99616"}}>
                                { data.subHeader ? data.subHeader : data.form.subTitle}
                            </p>
                        </div>
                        <div className="mt-12">
                            <FormsyInput
                                name="fullName"
                                placeholder={data.form.fullName}
                                classes="mt-4"
                                required
                            />
                            <FormsyInput
                                name="email"
                                validations={"isEmail"}
                                validationErrors={{
                                    isEmail: data.form.email.errorMessage,
                                }}
                                placeholder={data.form.email.label}
                                classes="mt-4"
                                required
                            />
                            <FormsyInput
                                name="telephone"
                                validations={"minLength:10"}
                                type="number"
                                validationErrors={{
                                    minLength: data.form.telephone.errorMessage,
                                }}
                                placeholder={`${data.form.telephone.label} ${data.teleRequired ? "*" : ""}`}
                                classes="mt-4"
                                required={data.teleRequired ? true : false}
                            />

                            { props.subjectRequired && (
                                <FormsyInput
                                    name="subject"
                                    placeholder={data.form.subject}
                                    classes="mt-4"
                                    defaultValue={data.subject}
                                    required
                                />
                            )}

                            { props.messageRequired && (
                                <FormsyTextArea
                                    name="message"
                                    validations={"minLength: 9"}
                                    validationErrors={{
                                        minLength: data.form.message.errorMessage,
                                    }}
                                    placeholder={data.form.message.label}
                                    classes="mt-4"
                                    required
                                />
                            )}

                        </div>
                        { canSubmit
                            ?
                            <button className={"bttn-active cf-bttn"}>
                                {data.form.sendButton}
                            </button>
                            :
                            <button className={"cf-bttn"} onClick={test}>
                                {data.form.sendButton}
                            </button>
                        }
                        { showErrorMessage && (
                            <p className="text-center mt-8" style={{color: "#b70000",fontSize: "1.6rem",}}>Συμπληρώστε όλα τα απαραίτητα πεδία (*)</p>
                        )}
                    </Formsy>
                    :
                    <div >
                       <div className="email-response" dangerouslySetInnerHTML={{ __html: emailResponse }} />
                    </div>
                }

            </div>
        </div>
    )
}

export default ContactForm;